@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lato&display=swap');

body,
html {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

body:not(.p-0) {
  padding-bottom: 60px;
}

@media only screen and (min-width: 885px) {
  body:not(.p-0) {
    padding-bottom: 40px;
  }
}
